export type AssignAssetsProps = {
    modalIsOpen: boolean;
    successMessage: string;
    failureMessage: string;
    assetObj?: any
    setIsLoading: (...props: any) => any;
    toogleModal: (...props: any) => any;
    setAlert: (...props: any) => any;
    onSuccess: (...props: any) => any;
};

export type AssignAssetsForm = {
    owner: string;
    description: string;
    assignAssets: any[];
    assignAccessory: any[];
};

export enum AssignAssetsFieldnames {
    OWNER = 'owner',
    DESCRIPTION = 'description',
    ASSIGN_ASSET = 'assignAssets',
    ASSIGN_ACCESSORY = 'assignAccessory'
}
